import {Container, Stack, Typography} from "@mui/material";
import AllLicensesTable from "./AllLicensesTable";

export default function AllLicensesPage() {
    return <Container>
        <Stack direction="column" alignItems="start">
            <Typography variant="h2">Alle Lizenzen von OrderAssist</Typography>
            <AllLicensesTable/>
        </Stack>
    </Container>
}