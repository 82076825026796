import {Box, ButtonBase} from '@mui/material';
import { Link } from "react-router-dom";
import {ReactNode} from "react";


interface Props {
    active: boolean;
    icon: ReactNode;
    path: string;
    title: string;
}

export function SideNavItem(props: Props) {
    const {
        active,
        icon,
        path,
        title
    } = props;

    const linkProps = path
        ? {
            component: Link,
            to: path
        }
        : {};

    return (
        <li>
            <ButtonBase
                sx={{
                    alignItems: 'center',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    pl: '16px',
                    pr: '16px',
                    py: '6px',
                    textAlign: 'left',
                    width: '100%',
                    /*
                    * ...(active && {
                        backgroundColor: 'rgba(255, 255, 255, 0.04)'
                    }),

                    * */
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.05)'
                    }


                }}
                {...linkProps}
            >
                {icon && (
                    <Box
                        component="span"
                        sx={{
                            alignItems: 'center',
                            color: '#0e0e0e',
                            display: 'inline-flex',
                            justifyContent: 'center',
                            mr: 2,
                            ...(active && {
                                color: '#266db4'
                            })
                        }}
                    >
                        {icon}
                    </Box>
                )}
                <Box
                    component="span"
                    sx={{
                        color: '#0e0e0e',
                        flexGrow: 1,
                        fontFamily: (theme) => theme.typography.fontFamily,
                        fontSize: 14,
                        fontWeight: 600,
                        lineHeight: '24px',
                        whiteSpace: 'nowrap',
                        ...(active && {
                            color: '#266db4'
                        }),
                    }}
                >
                    {title}
                </Box>
            </ButtonBase>
        </li>
    );
}
