import {Button, Container, Stack, Typography} from "@mui/material";
import LicensesTable from "./LicensesTable";
import {Link} from "react-router-dom";


export default function LicensesScreen() {
    return <Container>
        <Stack direction="column" alignItems="start">
            <Typography variant="h2">Meine Lizenzen</Typography>
            <Button
                component={Link}
                to="/license_creation"
                variant="contained"
                sx={{mt: 0, mb: 4}}>Neue Lizenz</Button>
            <LicensesTable/>
        </Stack>
    </Container>
}
