import {Alert} from "@mui/material";

interface Props {
    error: string | null;
}

export default function ErrorBanner(props: Props) {
    if (props.error == null) {
        return <></>
    } else {
        return <Alert sx={{mt: 1, mb: 1}} severity="error">{props.error}</Alert>;
    }
}