import {Outlet, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import {Box, styled} from "@mui/material";
import {TopNav} from "./TopNav";
import {SideNav} from "./SideNav";

const SIDE_NAV_WIDTH = 280;

const LayoutRoot = styled('div')(({theme}) => ({
    display: 'flex',
    flex: '1 1 auto',
    maxWidth: '100%',
    [theme.breakpoints.up('lg')]: {
        paddingLeft: SIDE_NAV_WIDTH
    }
}));

const LayoutContainer = styled('div')({
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    width: '100%'
});

export function LayoutsWithNavbar() {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <>
            <TopNav onNavOpen={() => setNavOpen(true)}/>
            <SideNav
                onClose={() => setNavOpen(false)}
                open={navOpen}
            />
            <LayoutRoot>
                <LayoutContainer>
                    <Box sx={{ml: 5, mr: 5, mt: 5, mb: 5}}>
                        <Outlet/>
                    </Box>
                </LayoutContainer>
            </LayoutRoot>
        </>
    );
}
