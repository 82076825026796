import {
    Alert, Box,
    Button, Card,
    Container, Dialog, DialogContent, DialogTitle,
    Divider,
    Modal,
    Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from "@mui/material";
import {useState} from "react";


export default function EventScreen() {
    const [showDialog, setShowDialog] = useState(false);

    return <Container>
        <Typography variant="h1">Meine Veranstaltungen</Typography>
        <Divider sx={{mt: 1}}/>

        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Veranstaltung</TableCell>
                    <TableCell>Startdatum</TableCell>
                    <TableCell>Enddatum</TableCell>
                    <TableCell>Anzahl Lizenzen</TableCell>
                    <TableCell>Lizenzcode</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Schlachtplatte 2023</TableCell>
                    <TableCell>10.10.2023</TableCell>
                    <TableCell>10.11.2023</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell><Button onClick={() => setShowDialog(true)}>Lizenzschlüssel</Button></TableCell>
                </TableRow>
                <tr>
                    <TableCell>Schlachtplatte 2023</TableCell>
                    <TableCell>10.10.2023</TableCell>
                    <TableCell>10.11.2023</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell><Button disabled>Lizenzschlüssel</Button></TableCell>
                </tr>
            </TableBody>
        </Table>

        <Dialog open={showDialog}
               onClose={() => setShowDialog(false)}>
            <DialogTitle>
                Lizenzschlüssel
            </DialogTitle>

            <DialogContent>
                <Alert icon={false}>

                </Alert>

                <Alert
                    icon={false}
                    variant="outlined"
                    color="warning"
                    sx={{mt: 1}}>
                    Die Lizenz kann mit diesem Schlüssel nur einmalig abgerufen werden. Daher am besten erst kurz
                    vor der Veranstaltung eingeben (geht die Lizenz verloren, z.B. wenn die App gelöscht wird ist
                    sie nicht mehr nutzbar).
                </Alert>
            </DialogContent>
        </Dialog>

    </Container>
}
