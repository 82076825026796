import {Button, Card, CardContent, Stack, Typography} from "@mui/material";
import {Link, useSearchParams} from "react-router-dom";
import {Close} from "@mui/icons-material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function PurchaseCompletedScreen() {
    const [searchParams] = useSearchParams();
    const state = searchParams.get("state")

    return <>
        <Stack alignItems="center">
            {
                state === "success" &&
                <>
                    <Card variant="outlined" sx={{maxWidth: 400}}>
                        <CardContent>
                            <Stack direction="column" gap={3} alignItems="center">
                                <CheckCircleOutlineIcon color="success"
                                                        sx={{fontSize: 70}}
                                />

                                <Typography variant="h4">Danke für die Bestellung!</Typography>

                                <Typography mt={1} sx={{textAlign: "center"}}>
                                    Sobald die Bestellung erfolgreich abgewickelt wurde erhältst Du eine E-Mail
                                    und kannst die Lizenz im Bereich "Meine Lizenzen" abrufen.
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Link to="/licenses">
                        <Button variant="outlined" sx={{mt: 5}}>Meine Lizenzen</Button>
                    </Link>
                </>
            }
            {
                state !== "success" &&
                <>
                    <Card variant="outlined" sx={{maxWidth: 400}}>
                        <CardContent>
                            <Stack direction="column" gap={3} alignItems="center">
                                <Close color="error"
                                       sx={{fontSize: 70}}
                                />

                                <Typography variant="h4">Ups...</Typography>

                                <Typography mt={1} sx={{textAlign: "center"}}>
                                    Bei der Bezahlung hat etwas nicht funktioniert. Bitte versuche es später erneut.
                                </Typography>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Link to="/license_creation">
                        <Button variant="outlined" sx={{mt: 5}}>Erneut versuchen</Button>
                    </Link>
                </>
            }
        </Stack>
    </>
}