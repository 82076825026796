import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {LoginScreen} from "../../pages/authentication/ui/LoginScreen";
import * as React from "react";
import {NotFoundPage} from "../../common/ui/NotFoundPage";
import {LayoutsWithNavbar} from "./LayoutWithNavbar";
import HomeScreen from "../../pages/home_screen/ui/HomeScreen";
import {authenticationStateHandler} from "../../pages/authentication/logic/AuthenticationStateHandler";
import {useObservable} from "../../common/ui/ObservableHook";
import EventScreen from "../../pages/events/ui/EventScreen";
import ConfigureLicenseScreen from "../../pages/configure_license/ui/ConfigureLicenseScreen";
import {RegistrationScreen} from "../../pages/authentication/ui/RegistrationScreen";
import {ResetPasswordScreen} from "../../pages/authentication/ui/ResetPasswordSceen";
import LinkingEmailConfirmationScreen from "../../pages/authentication/ui/LinkingEmailConfirmationScreen";
import LinkingResetPasswordScreen from "../../pages/authentication/ui/LinkingResetPasswordScreen";
import PurchaseCompletedScreen from "../../pages/configure_license/ui/PurchaseCompletedScreen";
import LicensesScreen from "../../pages/licenses/ui/LicensesScreen";
import AllUsersPage from "../../pages/admin/ui/AllUsersPage";
import AllLicensesPage from "../../pages/admin/ui/AllLicensesPage";
import CreateLicensePage from "../../pages/admin/ui/CreateLicensePage";

export function RouteDefinitions() {
    const location = useLocation();

    const isAuthenticated = useObservable(
        authenticationStateHandler.isAuthenticatedObservable,
        authenticationStateHandler.isAuthenticated
    ) ?? false;

    return <Routes location={location}>
        <Route path="/" element={<LayoutsWithNavbar/>}>
            <Route path="/" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<HomeScreen/>}/>
            }/>
            <Route path="/licenses" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<LicensesScreen/>}/>
            }/>
            <Route path="/events" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<EventScreen/>}/>
            }/>
            <Route path="/license_creation" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<ConfigureLicenseScreen/>}/>
            }/>
            <Route path="/license_creation/purchase_completed" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<PurchaseCompletedScreen/>}/>
            }/>
            <Route path="/admin/all-users" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<AllUsersPage/>}/>
            }/>
            <Route path="/admin/all-licenses" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<AllLicensesPage/>}/>
            }/>
            <Route path="/admin/create-licenses" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<CreateLicensePage/>}/>
            }/>
            <Route path="*" element={
                <ProtectedRoute isLoggedIn={isAuthenticated} element={<NotFoundPage/>}/>
            }/>
        </Route>

        <Route path="/login" element={<LoginScreen navigateToUrlWhenLoggedIn="/"/>}/>
        <Route path="/register" element={<RegistrationScreen/>}/>
        <Route path="/reset_password" element={<ResetPasswordScreen/>}/>
        <Route path="/linking/email_confirmation"
               element={<LinkingEmailConfirmationScreen navigateToUrlWhenConfirmed="/"/>}/>
        <Route path="/linking/password_reset" element={<LinkingResetPasswordScreen/>}/>
    </Routes>;
}

interface ProtectedRouteProps {
    isLoggedIn: boolean,
    element: React.JSX.Element
}

function ProtectedRoute(props: ProtectedRouteProps): React.JSX.Element {
    if (props.isLoggedIn) {
        return props.element;
    } else {
        return <Navigate to="/login"/>
    }
}
