import useSWR from "swr";
import {getFetcher} from "../../../hooks/ApiHooks";
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import LoadingState from "../../../common/ui/LoadingState";
import License from "../../licenses/License";
import dayjs from "dayjs";


const StyledTableRow = styled(TableRow)(({theme}) => ({
    backgroundColor: theme.palette.action.hover,
    fontWeight: "bolder",
    "> tr,th": {
        fontWeight: "bolder",
    }
}));


export default function AllLicensesTable() {
    const {
        data: users,
        error,
        isLoading
    } = useSWR(
        `/api/v1/licenses`,
        url => getFetcher<License[]>(
            url,
            "Die Lizenzen konnten nicht geladen werden"
        ))


    return <>
        <TableContainer sx={{width: '100%'}}>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <TableCell
                            style={{minWidth: 100}}>Nutzer</TableCell>
                        <TableCell
                            style={{minWidth: 100}}>Veranstaltung</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Startdatum</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Enddatum</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Anzahl Geräte</TableCell>
                        <TableCell
                            style={{minWidth: 175}}>Lizenzcode</TableCell>
                        <TableCell
                            style={{minWidth: 150}}>Zustand</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        users?.map(l =>
                            <TableRow hover key={l.id}>
                                <TableCell>{l.licenseIssuer}</TableCell>
                                <TableCell>{l.licensePurpose}</TableCell>
                                <TableCell>{dayjs(l.startDate).format("DD.MM.YYYY HH:ss [Uhr]")}</TableCell>
                                <TableCell>{dayjs(l.expirationDate).format("DD.MM.YYYY HH:ss [Uhr]")}</TableCell>
                                <TableCell>{l.maxDevicesInEvent}</TableCell>
                                <TableCell>{l.licenseKey}</TableCell>
                                <TableCell>{l.state}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <LoadingState error={error} isLoading={isLoading}/>
    </>
}