import {Box, Button, Container, Typography} from "@mui/material";
import {ConfigureLicenseComponent, ConfigureLicenseState} from "../../configure_license/ui/ConfigureLicenseComponent";
import * as React from "react";
import {useApiPost} from "../../../hooks/ApiHooks";
import LoadingState from "../../../common/ui/LoadingState";

export default function CreateLicensePage() {
    return <Container>
        <Typography variant="h1">Neue Lizenz</Typography>
        <ConfigureLicenseComponent
            continueButton={(state, isValid) =>
                <CreateLicenseButton state={state} isValid={isValid}/>}
        />
    </Container>
}

interface CreateLicenseButtonProps {
    state: ConfigureLicenseState,
    isValid: boolean
}


function CreateLicenseButton(props: CreateLicenseButtonProps) {
    const {
        doRequest: onCreate,
        isLoading,
        error
    } = useApiPost<any, {licenseKey: string}>(
        `/api/v1/licenses`,
        {
            license: {
                startDate: props.state.startDate?.startOf("day").format("YYYY-MM-DD") ?? "",
                expirationDate: props.state.endDate?.startOf("day").format("YYYY-MM-DD") ?? "",
                maxDevicesInEvent: parseInt(props.state.numberDevices),
                licensePurpose: props.state.eventName,
            }
        },
        "Die Lizenz konnte nicht erstellt werden.",
        () => {
            window.location.href = "/licenses"
        }
    );

    return <>
        <Button
            variant="contained"
            sx={{mt: 2}}
            fullWidth
            disabled={!props.isValid || isLoading}
            onClick={onCreate}
        >
            Lizenz erstellen
        </Button>
        <Box mt={2}>
            <LoadingState isLoading={isLoading} error={error}></LoadingState>
        </Box>
    </>
}
