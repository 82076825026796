import {Observable} from "rxjs";
import {useEffect, useState} from "react";


/**
 * This hooks provides a way to observe RxJS observables. It automatically unsubscribes when not needed anymore.
 *
 * @param observable The observable to observe
 * @param defaultValue The default value until the first value gets emitted
 */
export function useObservable<T>(observable: Observable<T>, defaultValue: T | null = null): T | null {
    const [state, setState] = useState<T | null>(defaultValue);

    useEffect(() => {
        let subscription = observable.subscribe(setState)

        return () => {
            subscription.unsubscribe();
        }
    }, [observable]);

    return state;
}
