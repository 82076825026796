import {Button, Container, Divider, Stack, styled, Typography} from "@mui/material";
import {Link} from "react-router-dom";

const InfoCard = styled('div')({
    borderRadius: '15px',
    background: 'linear-gradient(130deg, rgba(238,240,249,1) 0%, rgba(242,237,227,1) 100%, rgba(0,212,255,1) 100%)'
});


export default function HomeScreen() {
    return <Container>
        <Typography variant="h2">Hallo!</Typography>
        <Divider/>

        <InfoCard sx={{mt: 4, p:3}}>
            <Stack alignItems="flex-start">
                <Typography>
                    Stemme deine nächste Veranstaltung mit OrderAssist und
                    erstelle die App-Lizenz ganz einfach online über unser Portal:
                </Typography>
                <Button
                    component={Link}
                    to="/license_creation"
                    variant="contained"
                    sx={{mt: 2}}>Neue Lizenz</Button>
            </Stack>

        </InfoCard>
    </Container>
}
