import React, {SetStateAction, useState} from "react";
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Stack, TextField,
    Typography
} from "@mui/material";
import {doApiPost} from "../../../api/Requests";

export function ResetPasswordScreen() {
    const [email, setEmail] = useState("")
    const [isError, setIsError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const onResetPassword = async function () {
        setIsError(false);
        setIsSuccess(false);
        setIsLoading(true);

        const result = await doApiPost<any, never>(
            "/api/users/password_reset",
            {eMail: email},
            {handleUnauthenticated: false}
        );

        setIsLoading(false);
        if (result.success()) {
            setIsSuccess(true);
        } else {
            setIsError(true);
        }
    }

    return <Container component="main" maxWidth="xs" sx={{mt: 10}}>
        <CssBaseline/>
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <Typography variant="h1" sx={{alignSelf: "center", textAlign: "center"}} mb={5}>
                Passwort zurücksetzen
            </Typography>
            <Box>
                <Stack gap={2}>
                    {
                        !isSuccess && <>
                            <TextField
                                fullWidth
                                variant="outlined"
                                disabled={false}
                                placeholder="E-Mail"
                                value={email}
                                onChange={(v: { target: { value: SetStateAction<string>; }; }) => setEmail(v.target.value)}
                            />
                            {
                                isError &&
                                <Alert severity="error"
                                       variant="standard">
                                    Das Passwort konnte nicht zurückgesetzt werden. Prüfen Sie, ob die angegebene E-Mail
                                    Adresse korrekt eingegeben wurde.
                                </Alert>
                            }
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                disabled={isLoading}
                                sx={{mt: 3}}
                                onClick={onResetPassword}
                            >
                                {
                                    isLoading && <><CircularProgress/></>
                                }
                                {
                                    !isLoading && <>Passwort zurücksetzen</>
                                }
                            </Button>
                        </>
                    }

                    {
                        isSuccess &&
                        <Alert severity="success">
                            <Typography>
                                E-Mail zur Kontowiederherstellung an <b>{email}</b> gesendet
                            </Typography>
                        </Alert>
                    }
                </Stack>
            </Box>
        </Stack>
    </Container>
}