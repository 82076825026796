import {MenuItem} from "@mui/material";
import {doApiPost} from "../../../api/Requests";
import {authenticationStateHandler} from "../logic/AuthenticationStateHandler";


export default function LogoutButton() {
    let handleLogout = async function () {
        let result = await doApiPost<any, never>(
            "/api/v1/auth/logout",
            {}
        );

        if(result.success()) {
            authenticationStateHandler.setNotAuthenticated();
        }
    }

    return <MenuItem onClick={() => handleLogout()}>Abmelden</MenuItem>
}
