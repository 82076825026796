import {useState} from "react";
import {doApiGet, doApiPost} from "../api/Requests";


export function useApiPost<TRequest, TResponse>(
    url: string,
    data: TRequest | null,
    errorMessage: string,
    onSuccess: (response: TResponse | null) => void,
) {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const doRequest = async function () {
        if (data == null) return

        setIsLoading(true)

        const result = await doApiPost<TRequest, TResponse>(url, data)

        if (result.success()) {
            try {
                onSuccess(result.result)
            } catch (e) {
                setError(errorMessage);
            }
        } else {
            setError(errorMessage)
        }

        setIsLoading(false)
    }


    return {
        isLoading,
        doRequest,
        error,
    }
}

export async function getFetcher<TResponse>(url: string, error: string): Promise<TResponse> {
    const result = await doApiGet(url);
    const data = result.result;

    if (data == null) {
        throw error
    } else {
        return data as TResponse
    }
}

export async function getMapFetcher<TResponse, TMapped>(url: string, error: string, mapFn: (r: TResponse) => TMapped): Promise<TMapped> {
    const result = await doApiGet(url);
    const data = result.result;

    if (data == null) {
        throw error
    } else {
        return mapFn(data as TResponse) as TMapped
    }
}