import {useNavigate, useSearchParams} from "react-router-dom";
import {Alert, CircularProgress, Container, CssBaseline, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {doApiPost, ErrorType} from "../../../api/Requests";
import {authenticationStateHandler} from "../logic/AuthenticationStateHandler";
import {loginAndReturnTo} from "./LoginScreen";

interface Props {
    navigateToUrlWhenConfirmed: string
}


/**
 * This screen handles the email verification link. It uses the url-provided token and validates
 * it with the backend. It logs in the user if needed.
 * @param props
 * @constructor
 */
export default function LinkingEmailConfirmationScreen(props: Props) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isError, setIsError] = useState(false);
    const token = searchParams.get("token");
    const url = window.location.href;

    useEffect(() => {
        const validateEmail = async function () {
            const userId = authenticationStateHandler.profile?.id;

            if (!userId) {
                loginAndReturnTo(url)
                return
            }

            const result = await doApiPost<any, never>(
                `/api/users/${userId}/confirmation/${token}`,
                null,
                {handleUnauthenticated: true}
            );

            if (result.success()) {
                navigate(props.navigateToUrlWhenConfirmed)
            } else if (result.errorType === ErrorType.AuthenticationError) {
                loginAndReturnTo(url)
            } else {
                setIsError(true);
            }
        }

        validateEmail().then(() => {
        });
        return () => {
        };
    }, [token, navigate, props.navigateToUrlWhenConfirmed, url])

    return <Container component="main" maxWidth="xs" sx={{mt: 10}}>
        <CssBaseline/>
        <Stack gap={1} alignItems="center">
            <Typography variant="h1" sx={{alignSelf: "center"}} mb={5}>
                E-Mail validieren...
            </Typography>

            {
                !isError &&
                <CircularProgress/>
            }
            {
                isError &&
                <Alert severity="error">
                    Die E-Mail konnte nicht validiert werden.
                </Alert>
            }
        </Stack>
    </Container>
}
