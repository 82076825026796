import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Alert,
    Avatar,
    Box,
    Button,
    CircularProgress,
    Container,
    CssBaseline,
    Stack, TextField,
    Typography
} from "@mui/material";
import {FormEvent, useState} from "react";
import {doApiPost} from "../../../api/Requests";

interface ResetState {
    password: string,
    passwordRepeated: string,
    email: string,
}


/**
 * This screen handles the password reset link. It uses the url-provided token and prompts the
 * user for the email and new password. After the password reset the user gets redirected to
 * the login page where he can log in normally.
 */
export default function LinkingResetPasswordScreen() {
    const navigate = useNavigate();
    const [state, setState] = useState<ResetState>({
        password: "",
        passwordRepeated: "",
        email: "",
    });
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const token = searchParams.get("token");


    const onHandleResetPassword = async function (event: FormEvent) {
        // We use a form with preventDefault as button click listener. This is needed to
        // leverage the browsers built in save password feature
        event.preventDefault()

        setIsLoading(true);

        const result = await doApiPost<any, never>(
            `/api/users/password_reset/${token}`,
            {
                eMail: state.email,
                newPassword: state.password
            },
            {handleUnauthenticated: true}
        );

        if (result.success()) {
            navigate("/")
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    }


    return <Container component="main" maxWidth="xs" sx={{mt: 10}}>
        <CssBaseline/>
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <Avatar sx={{m: 1, alignSelf: "center"}}>
            </Avatar>
            <Typography variant="h1" sx={{alignSelf: "center", textAlign: "center"}} mb={5}>
                Passwort zurücksetzen
            </Typography>
            <Box component="form" onSubmit={onHandleResetPassword} noValidate>
                <Stack gap={2}>
                    <TextField
                        fullWidth
                        disabled={false}
                        placeholder="E-Mail"
                        variant="outlined"
                        value={state.email}
                        onChange={(v) =>
                            setState({...state, email: v.target.value})}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        placeholder="Passwort"
                        value={state.password}
                        onChange={(v) =>
                            setState({...state, password: v.target.value})}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        placeholder="Passwort wiederholen"
                        value={state.passwordRepeated}
                        error={state.password !== state.passwordRepeated}
                        onChange={(v) =>
                            setState({...state, passwordRepeated: v.target.value})}
                    />
                    {
                        isError &&
                        <Alert severity="error">
                            Das Passwort konnte nicht geändert werden, bitte prüfen Sie die Eingaben und versuchen Sie
                            es erneut.
                        </Alert>
                    }
                    <Button
                        fullWidth
                        type="submit"
                        disabled={isLoading}
                        sx={{mt: 3}}
                    >
                        {
                            isLoading && <><CircularProgress/></>
                        }
                        {
                            !isLoading && <>Passwort ändern</>
                        }
                    </Button>
                </Stack>
            </Box>
        </Stack>
    </Container>
}
