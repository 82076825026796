import ErrorBanner from "./ErrorBanner";
import {CircularProgress} from "@mui/material";

interface Props {
    error: string | null;
    isLoading: boolean;
}

export default function LoadingState(props: Props) {
    return <>
        <ErrorBanner error={props.error}/>
        {
            props.isLoading &&
            <CircularProgress/>
        }
    </>
}