import {Container, Stack, Typography} from "@mui/material";
import AllUsersTable from "./AllUsersTable";


export default function AllUsersPage() {
    return <Container>
        <Stack direction="column" alignItems="start">
            <Typography variant="h2">Alle Nutzer von OrderAssist</Typography>
            <AllUsersTable/>
        </Stack>
    </Container>
}