import {authenticationStateHandler} from "../pages/authentication/logic/AuthenticationStateHandler";

export const API_BASE_URL = ""

export class CallResult<T> {
    readonly result: T | null;
    readonly status: number | null;
    readonly errorType: ErrorType | null;

    constructor(
        result: T | null,
        status: number | null,
        type: ErrorType | null,
    ) {
        this.result = result;
        this.status = status;
        this.errorType = type;
    }

    getResultOrNull(): T | null {
        return this.result;
    }

    getStatusOrNull(): number | null {
        return this.status;
    }

    success(): boolean {
        return this.errorType == null;
    }
}

export enum ErrorType {
    AuthenticationError,
    AuthorizationError,
    ApiError,
    NetworkError,
    UnknownError
}


/**
 * Internal function to wrap all calls to the fetch(...) API. This is needed to
 * ensure that the response and error handling is done the same way for
 * GET, POST, ...
 *
 * This handles the 401 (not authenticated) response and tells the rest of the application
 * that we are not authenticated.
 *
 * raw: if true, do not try to parse the json and return the raw response as blob instead.
 *      T is expected to be Blob in this case.
 */
export async function fetchApiWrapper<T>(
    call: (() => Promise<Response>),
    raw: boolean = false,
    handleUnauthenticated: boolean = true,
): Promise<CallResult<T>> {
    try {
        const rawResponse = await call();

        if (rawResponse.status === 401) {
            if (handleUnauthenticated) {
                authenticationStateHandler.setNotAuthenticated();
            }
            return new CallResult<T>(null, 401, ErrorType.AuthenticationError);
        }

        if (rawResponse.status === 403) {
            return new CallResult<T>(null, 403, ErrorType.AuthorizationError);
        }

        const textResult = await rawResponse.text()
        let result: T | null = null;

        if (textResult.length > 0) {
            result = JSON.parse(textResult) as T;
        }
        if (!rawResponse.ok) {
            return new CallResult<T>(result, rawResponse.status, ErrorType.ApiError);
        }

        if (raw) {
            return new CallResult<T>(
                await rawResponse.blob() as T,
                rawResponse.status,
                null
            );
        }

        return new CallResult<T>(result, rawResponse.status, null);
    } catch (err) {
        if (err instanceof TypeError) {
            // Error thrown by fetch which indicates connectivity issues
            return new CallResult<T>(null, null, ErrorType.NetworkError);
        } else {
            return new CallResult<T>(null, null, ErrorType.UnknownError);
        }
    }
}

/**
 * Execute a GET API call.
 */
export async function doApiGet<T>(
    url: string,
    raw: boolean = false,
    handleUnauthenticated: boolean = true,
    options?: {
        accessToken?: string,
    }
): Promise<CallResult<T>> {
    return fetchApiWrapper(
        async () =>
            await fetch(
                API_BASE_URL + url,
                {
                    method: 'GET',
                    headers: {
                        "Authorization": `Bearer ${options?.accessToken ?? authenticationStateHandler.accessToken}`
                    }
                }),
        raw,
        handleUnauthenticated
    )
}

/**
 * Execute a POST API call.
 */
export async function doApiPost<TRequest, TResponse>(
    url: string,
    body: TRequest,
    options?: {
        raw?: boolean,
        handleUnauthenticated?: boolean,
        accessToken?: string
    }
): Promise<CallResult<TResponse>> {
    return fetchApiWrapper(
        async () =>
            await fetch(API_BASE_URL + url, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${options?.accessToken ?? authenticationStateHandler.accessToken}`
                }
            }),
        options?.raw ?? false,
        options?.handleUnauthenticated ?? true
    )
}
