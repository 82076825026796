import {Box, Button, Divider, MenuList, Popover, Typography} from '@mui/material';
import LogoutButton from "../../pages/authentication/ui/LogoutButton";
import {useObservable} from "../../common/ui/ObservableHook";
import {authenticationStateHandler} from "../../pages/authentication/logic/AuthenticationStateHandler";
import {Link} from "react-router-dom";

interface Props {
    anchorEl: any;
    onClose: () => void;
    open: boolean;
}


export function AccountPopover(props: Props) {
    const profile = useObservable(authenticationStateHandler.profileObservable)
    const {anchorEl, onClose, open} = props;

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'left',
                vertical: 'bottom'
            }}
            onClose={onClose}
            open={open}
            slotProps={{
                paper: {sx: {width: 200}}
            }}
        >
            <Box
                sx={{
                    py: 1.5,
                    px: 2
                }}
            >
                <Typography variant="overline">
                    Account
                </Typography>

                <Typography
                    color="text.secondary"
                    variant="body2"
                >
                    {profile?.firstName} {profile?.lastName}
                </Typography>
            </Box>
            <Divider/>
            <MenuList
                disablePadding
                dense
                sx={{
                    p: '8px',
                    '& > *': {
                        borderRadius: 1
                    }
                }}
            >
                <LogoutButton/>
            </MenuList>
        </Popover>
    );
}
