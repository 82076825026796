import {
    Alert,
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle, Stack, styled,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableHead,
    TableRow, Typography,
} from "@mui/material";
import {useState} from "react";
import useSWR from "swr";
import {getFetcher} from "../../../hooks/ApiHooks";
import LoadingState from "../../../common/ui/LoadingState";
import dayjs from "dayjs";
import License from "../License";

const StyledTableRow = styled(TableRow)(({theme}) => ({
    backgroundColor: theme.palette.action.hover,
    fontWeight: "bolder",
    "> tr,th": {
        fontWeight: "bolder",
    }
}));


export default function LicensesTable() {
    const {
        data: licenses,
        error,
        isLoading
    } = useSWR(
        `/api/users/current/licenses`,
        url => getFetcher<License[]>(
            url,
            "Die Lizenzen konnten nicht geladen werden"
        ))


    return <>
        <TableContainer sx={{width: '100%'}}>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <TableCell
                            style={{minWidth: 100}}>Veranstaltung</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Startdatum</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Enddatum</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Anzahl Geräte</TableCell>
                        <TableCell
                            style={{minWidth: 150}}>Lizenzcode</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        licenses?.map(l =>
                            <TableRow hover key={l.id}>
                                <TableCell>{l.licensePurpose}</TableCell>
                                <TableCell>{dayjs(l.startDate).format("DD.MM.YYYY HH:ss [Uhr]")}</TableCell>
                                <TableCell>{dayjs(l.expirationDate).format("DD.MM.YYYY HH:ss [Uhr]")}</TableCell>
                                <TableCell>{l.maxDevicesInEvent}</TableCell>
                                <TableCell><ShowLicenseKeyButton license={l}/></TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        {
            licenses && licenses.length === 0 &&
            <Stack sx={{mt: 10, width: "100%"}} alignItems="center">
                <Typography>Du hast noch keine Lizenzen. </Typography>
            </Stack>
        }

        <LoadingState error={error} isLoading={isLoading}/>
    </>
}

interface ShowLicenseKeyButtonProps {
    license: License;
}

function ShowLicenseKeyButton(props: ShowLicenseKeyButtonProps) {
    const [open, setOpen] = useState<boolean>(false);

    return <>
        {
            props.license.state === "Redeemed" &&
            <Typography>Bereits eingelöst</Typography>
        }
        {
            props.license.state === "ReadyToRedeem" &&
            <>
                <Button onClick={() => setOpen(true)}>
                    Lizenzschlüssel
                </Button>

                <Dialog open={open}
                        onClose={() => setOpen(false)}>
                    <DialogTitle>
                        Lizenzschlüssel
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            Dies ist der Lizenzschlüssel für "{props.license?.licensePurpose}":
                        </DialogContentText>

                        <Alert icon={false} sx={{mt: 2}}>
                            {props.license?.licenseKey ?? ""}
                        </Alert>

                        <Alert
                            icon={false}
                            variant="outlined"
                            color="warning"
                            sx={{mt: 5}}>
                            Die Lizenz kann mit diesem Schlüssel nur einmalig abgerufen werden. Daher am besten erst
                            kurz
                            vor der Veranstaltung eingeben (geht die Lizenz verloren, z.B. wenn die App gelöscht wird,
                            ist
                            sie nicht mehr nutzbar).
                        </Alert>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={() => setOpen(false)} autoFocus>
                            Schließen
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        }
    </>
}
