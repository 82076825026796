import {FormEvent, useState} from "react";
import {
    Alert,
    Avatar, Box,
    Button, CircularProgress,
    Container,
    CssBaseline, Divider, IconButton, InputAdornment, OutlinedInput,
    Stack, TextField,
    Typography
} from "@mui/material";
import {doApiPost} from "../../../api/Requests";
import {Link, useNavigate} from "react-router-dom";
import {authenticationStateHandler} from "../logic/AuthenticationStateHandler";
import {SimpleLink} from "../../../components/SimpleLink";
import * as React from "react";
import {Visibility, VisibilityOff} from "@mui/icons-material";

interface RegisterState {
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    passwordRepeated: string,
    companyName: string,
}

/**
 * This screen provides a form for company admin users to register
 */
export function RegistrationScreen() {
    const navigate = useNavigate();
    const [registerState, setRegisterState] = useState<RegisterState>({
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        passwordRepeated: "",
        companyName: "",
    });

    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState(false)

    const handleSignIn = async function (event: FormEvent) {
        // We use a form with preventDefault as button click listener. This is needed to
        // leverage the browsers built in save password feature
        event.preventDefault()

        setIsLoading(true);

        const result = await doApiPost<any, {
            accessToken: string,
            message?: string
        }>(
            "/api/v1/auth/register",
            {
                eMail: registerState.email,
                password: registerState.password,
                firstName: registerState.firstName,
                lastName: registerState.lastName,
            },
            {handleUnauthenticated: false}
        );

        const resultData = result.getResultOrNull()

        if (result.success() && resultData != null) {
            let success = await authenticationStateHandler.refreshProfileAndSetAuthenticated(
                resultData.accessToken
            );
            if (success) {
                navigate("/");
                return;
            }
        }
        if (resultData != null) {
            setErrorMessage(resultData.message)
        }

        setIsLoading(false);
        setIsError(true);
    }

    return <Container component="main" maxWidth="xs" sx={{mt: 10, mb: 10}}>
        <CssBaseline/>
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <Avatar sx={{m: 1, alignSelf: "center"}}>
            </Avatar>
            <Typography variant="h1" sx={{alignSelf: "center"}} mb={5}>
                Registrieren
            </Typography>

            <Typography variant="body1" sx={{alignSelf: "center"}} mb={5}>
                Registriere dich jetzt bei OrderAssist, um deine Lizenzen zu verwalten.
            </Typography>
            <Box component="form" onSubmit={handleSignIn} noValidate mb={1}>

                <Stack>
                    <Divider sx={{mt: 0, mb: 3}}></Divider>

                    <Typography variant="body1">
                        Persönliche Angaben
                    </Typography>

                    <Stack gap={2}>
                        <TextField
                            required
                            fullWidth
                            name="username"
                            disabled={false}
                            label="E-Mail"
                            variant="outlined"
                            value={registerState.email}
                            onChange={(v) =>
                                setRegisterState({...registerState, email: v.target.value})}
                        />
                        <TextField
                            required
                            fullWidth
                            disabled={false}
                            label="Vorname"
                            variant="outlined"
                            value={registerState.firstName}
                            onChange={(v) =>
                                setRegisterState({...registerState, firstName: v.target.value})}
                        />
                        <TextField
                            required
                            fullWidth
                            disabled={false}
                            label="Nachname"
                            variant="outlined"
                            value={registerState.lastName}
                            onChange={(v) =>
                                setRegisterState({...registerState, lastName: v.target.value})}
                        />
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Passwort"
                            type='password'
                            variant="outlined"
                            value={registerState.password}
                            onChange={(v) =>
                                setRegisterState({...registerState, password: v.target.value})}
                        />
                        <TextField
                            required
                            fullWidth
                            label="Passwort wiederholen"
                            type='password'
                            variant="outlined"
                            error={registerState.password !== registerState.passwordRepeated}
                            value={registerState.passwordRepeated}
                            onChange={(v) =>
                                setRegisterState({...registerState, passwordRepeated: v.target.value})}
                        />
                    </Stack>
                </Stack>

                <Stack gap={2} mt={3}>
                    {
                        isError &&
                        <Alert severity="error">
                            {errorMessage && <>{errorMessage}</>}
                            {!errorMessage && <>
                                Die Registrierung ist fehlgeschlagen. Prüfen Sie die Eingaben und
                                versuchen Sie es erneut.
                            </>}
                        </Alert>
                    }

                    <Typography variant="caption">
                        Mit der Registrierung erklärst du dich mit unseren <Link
                        to="https://order-assist.de/?page_id=1620">AGB</Link> einverstanden. In
                        unserer <Link to="https://order-assist.de/?page_id=1377">Datenschutzerklärung</Link> erläutern
                        wir die Verarbeitung deiner personenbezogenen Daten.
                    </Typography>

                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                        sx={{mt: 3}}
                    >
                        {
                            isLoading && <><CircularProgress/></>
                        }
                        {
                            !isLoading && <>Registrieren</>
                        }

                    </Button>
                </Stack>
            </Box>
            <SimpleLink to="/login">
                <Typography variant="subtitle2">Bereits registriert?</Typography>
            </SimpleLink>
        </Stack>
    </Container>
}