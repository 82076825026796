import {
    Box,
    Button,
    Container, Stack,
    Typography
} from "@mui/material";
import {useApiPost} from "../../../hooks/ApiHooks";
import BuyLicenseRequest from "../model/BuyLicenseRequest";
import BuyLicenseResponse from "../model/BuyLicenseResponse";
import LoadingState from "../../../common/ui/LoadingState";
import {ConfigureLicenseState, ConfigureLicenseComponent} from "./ConfigureLicenseComponent";
import * as React from "react";
import {Link} from "react-router-dom";

export default function ConfigureLicenseScreen() {

    return <Container>
        <Typography variant="h1">Neue Lizenz</Typography>
        <Typography mb={5}>Konfiguriere eine OrderAssist Lizenz für Deine Veranstaltung.</Typography>

        <ConfigureLicenseComponent
            continueButton={(state, isValid) => <>
                <BuyLicenseButton state={state} isValid={isValid}/>

                <Stack direction="column" gap={2}>
                    <Typography variant="caption" sx={{fontSize: "0.7em"}}>
                        Ich bin damit einverstanden, dass OrderAssist vor Ablauf der Widerrufsfrist mit der Vertragserfüllung beginnt.
                        Mir ist bewusst, dass ich mein Widerrufsrecht dadurch verliere. Widerrufsbelehrung und Widerrufsformular
                        finden sich <Link to="https://order-assist.de/?page_id=1645">hier</Link>
                    </Typography>

                    <Typography variant="caption" sx={{fontSize: "0.7em"}}>
                        Es gelten unsere <Link to="https://order-assist.de/?page_id=1620">AGB</Link>.
                        Bitte beachte unsere <Link to="https://order-assist.de/?page_id=1377">Datenschutzerklärung</Link>.
                    </Typography>
                </Stack>
            </>}
        />
    </Container>
}


interface BuyLicenseButtonProps {
    state: ConfigureLicenseState,
    isValid: boolean
}


function BuyLicenseButton(props: BuyLicenseButtonProps) {
    const {
        doRequest: onBuy,
        isLoading,
        error
    } = useApiPost<BuyLicenseRequest, BuyLicenseResponse>(
        `/api/users/current/orders/licenses`,
        {
            startDate: props.state.startDate?.startOf("day").format("YYYY-MM-DD") ?? "",
            expirationDate: props.state.endDate?.startOf("day").format("YYYY-MM-DD") ?? "",
            maxDevicesInEvent: parseInt(props.state.numberDevices),
            licensePurpose: props.state.eventName,
            paymentSuccessRedirectUrl: "/license_creation/purchase_completed?state=success",
            paymentErrorRedirectUrl: "/license_creation/purchase_completed?state=error",
        },
        "Die Lizenz konnte nicht gekauft werden. Prüfe, ob die Angaben korrekt eingegeben sind und versuche es später erneut.",
        (result) => {
            if (result?.orderSuccessful) {
                window.location.href = result.redirectUrl
            } else {
                throw "";
            }
        }
    );

    return <>
        <Button
            variant="contained"
            sx={{mt: 2}}
            fullWidth
            disabled={!props.isValid || isLoading}
            onClick={onBuy}
        >
            Jetzt kaufen
        </Button>
        <Box mt={2}>
            <LoadingState isLoading={isLoading} error={error}></LoadingState>
        </Box>
    </>
}
