import {FormEvent, SetStateAction, useState} from "react";
import {
    Alert,
    Avatar, Box,
    Button, CircularProgress,
    Container,
    CssBaseline,
    Stack, TextField,
    Typography
} from "@mui/material";
import {doApiPost} from "../../../api/Requests";
import {useNavigate, useSearchParams} from "react-router-dom";
import {authenticationStateHandler} from "../logic/AuthenticationStateHandler";
import {SimpleLink} from "../../../components/SimpleLink";

interface Props {
    navigateToUrlWhenLoggedIn: string
}

export function loginAndReturnTo(url: string) {
    window.location.href = `/login?returnUrl=${encodeURIComponent(url)}`
}

export function LoginScreen(props: Props) {
    const navigate = useNavigate();
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    //const [loginResult, setLoginResult] = useState<LoginResult | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)

    const [searchParams] = useSearchParams();
    const returnUrl = searchParams.get("returnUrl")
    const urlWhenLoggedIn = decodeURIComponent(returnUrl ?? props.navigateToUrlWhenLoggedIn);

    const handleSignIn = async function (event: FormEvent) {
        // We use a form with preventDefault as button click listener. This is needed to
        // leverage the browsers build in save password feature
        event.preventDefault()

        setIsError(false);
        setIsLoading(true);

        const result = await doApiPost<any, { accessToken: string }>(
            "/api/v1/auth/login",
            {email: email, password: password},
            {handleUnauthenticated: false}
        );

        setIsLoading(false);

        const resultData = result.getResultOrNull();

        if (result.success() && resultData != null) {
            let success = await authenticationStateHandler.refreshProfileAndSetAuthenticated(
                resultData.accessToken
            );

            if (success) {
                window.location.href = urlWhenLoggedIn;
                return;
            }
        } else {
            setIsError(true);
        }
    }

    return <Container component="main" maxWidth="xs" sx={{mt: 10, mb: 10}}>
        <CssBaseline/>
        <Stack gap={1} alignItems="stretch" ml={3} mr={3}>
            <Avatar sx={{m: 1, alignSelf: "center"}}>
            </Avatar>
            <Typography variant="h1" sx={{alignSelf: "center"}} mb={5}>
                Anmelden
            </Typography>
            <Box component="form" onSubmit={handleSignIn} noValidate mb={1}>
                <Stack gap={2}>
                    <TextField
                        fullWidth
                        disabled={false}
                        placeholder="E-Mail"
                        variant="outlined"
                        value={email}
                        onChange={(v: { target: { value: SetStateAction<string>; }; }) => setEmail(v.target.value)}
                    />
                    <TextField
                        fullWidth
                        type="password"
                        placeholder="Passwort"
                        value={password}
                        onChange={(v) => setPassword(v.target.value)}
                    />
                    {
                        isError &&
                        <Alert severity="error">
                            Die Anmeldung ist fehlgeschlagen. Prüfen Sie die E-Mail und das Passwort und versuchen Sie
                            es erneut.
                        </Alert>
                    }
                    <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        disabled={isLoading}
                        sx={{mt: 3}}
                    >
                        {
                            isLoading && <><CircularProgress/></>
                        }
                        {
                            !isLoading && <>Anmelden</>
                        }

                    </Button>
                </Stack>
            </Box>
            <SimpleLink to="/reset_password">
                <Typography variant="subtitle2">Passwort vergessen?</Typography>
            </SimpleLink>
            <SimpleLink to="/register">
                <Typography variant="subtitle2">Noch kein Account?</Typography>
            </SimpleLink>
        </Stack>
    </Container>
}