import React from 'react';
import '@fontsource/inter';
import {BrowserRouter} from "react-router-dom";
import {RouteDefinitions} from "./scaffold/ui/Routing";
import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";
import 'dayjs/locale/de';
import {deDE} from '@mui/x-date-pickers/locales';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";

const germanLocale = deDE.components.MuiLocalizationProvider.defaultProps.localeText;

const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: '#365990',
        },
        secondary: {
            main: '#243b5d',
        },
    },
    typography: {
        h1: {fontSize: "3rem"},
        h2: {fontSize: "2.5rem"},
        h3: {fontSize: "2.2rem"},
        h4: {fontSize: "1.75rem"},
        h5: {fontSize: "1.5rem"},
        h6: {fontSize: "1.25rem"},
    }
}));

/*const theme = extendTheme({
    colorSchemes: {
        light: {
            palette: {
                primary: {
                    50: '#649bff',
                    100: '#578ae2',
                    200: '#4b7ac6',
                    300: '#4069ab',
                    400: '#365990',
                    500: '#2c4a76',
                    600: '#243b5d',
                    700: '#1b2c46',
                    800: '#141e2f',
                    900: '#0a101a',
                },
            },
        },
    },
});
*/

function App() {
    return (
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}
                                  adapterLocale="de"
                                  localeText={germanLocale}>
                <BrowserRouter>
                    <RouteDefinitions/>
                </BrowserRouter>
            </LocalizationProvider>
        </ThemeProvider>
    );
}

export default App;
