import useSWR from "swr";
import {getFetcher} from "../../../hooks/ApiHooks";
import {
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import LoadingState from "../../../common/ui/LoadingState";
import User from "../model/User";


const StyledTableRow = styled(TableRow)(({theme}) => ({
    backgroundColor: theme.palette.action.hover,
    fontWeight: "bolder",
    "> tr,th": {
        fontWeight: "bolder",
    }
}));


export default function AllUsersTable() {
    const {
        data: users,
        error,
        isLoading
    } = useSWR(
        `/api/v1/users`,
        url => getFetcher<User[]>(
            url,
            "Die Benutzer konnten nicht geladen werden"
        ))


    return <>
        <TableContainer sx={{width: '100%'}}>
            <Table>
                <TableHead>
                    <StyledTableRow>
                        <TableCell
                            style={{minWidth: 100}}>Name</TableCell>
                        <TableCell
                            style={{minWidth: 100}}>E-Mail Adresse</TableCell>
                        <TableCell
                            style={{minWidth: 80}}>Registriert seit</TableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {
                        users?.map(l =>
                            <TableRow hover key={l.id}>
                                <TableCell>{l.firstName} {l.lastName}</TableCell>
                                <TableCell>{l.emailAddress}</TableCell>
                                <TableCell>{l.registeredSince}</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        <LoadingState error={error} isLoading={isLoading}/>
    </>
}